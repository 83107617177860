const Body = props => {
  const { activities, offset, handlePageClick } = props.data
  let activityLogBody
  if (activities[0] === '') {
    activityLogBody = null
  } else if (!activities.length) {
    activityLogBody = (
      <div className="no-activity-log">
        <div className="activity-text">
          <span className="no-activity-text">No activity found</span>
          <span className="clear-log" onClick={props.data.clearFilterHandler}>
            (clear)
          </span>
        </div>
      </div>
    )
  } else {
    const activityItems = activities.map((activity, index) => {
      let rowId = `user-row-${index}`
      return (
        <li className={'activity-row'} key={rowId}>
          {activity.title}
          {activity.is_new && (
            <ul className="changelog">
              {activity.changelog.map(change => {
                return <li className={'activity-row'}>{change}</li>
              })}
            </ul>
          )}
          {activity.meta['client_ip_address'] && (
            <span className="activity-row-meta">IP Address: {activity.meta['client_ip_address']}</span>
          )}
        </li>
      )
    })
    let paginationText
    if (offset) {
      let pageNum = offset * 100 + 1
      paginationText = (
        <div className="page-text">
          <span className="curr-log-page">
            Showing ({pageNum} - {pageNum + activities.length - 1})
          </span>
          <span className="page-button" onClick={handlePageClick.bind(null, 'prev')}>
            Previous (100)
          </span>
          /
        </div>
      )
    } else {
      paginationText = (
        <div>
          <span className="curr-log-page">Showing first ({activities.length}) results</span>
        </div>
      )
    }
    let nextButton
    if (activities.length < 100) {
      if (offset) {
        nextButton = <span className="page-button-disabled">Next (100)</span>
      } else {
        nextButton = <span className="page-button" />
      }
    } else {
      nextButton = (
        <span className="page-button" onClick={handlePageClick.bind(null, 'next')}>
          Next (100)
        </span>
      )
    }
    activityLogBody = (
      <div className="activity-log-body">
        <div className="activity-log-pagination">
          {paginationText}
          {nextButton}
        </div>
        <div className="activity-log-content">{activityItems}</div>
      </div>
    )
  }

  return <div className="activity-log-results">{activityLogBody}</div>
}

export default Body
