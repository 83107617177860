const {users, categories, media_url, locale} = INITDATA.getInitialData();
const userNames = users.map(user => user[1]);

const FilterBar = React.createClass({
  getInitialState() {
    const {fromDate, toDate, selectedUser, selectedCategory} = this.props.data;
    return {
      fromDate,
      toDate,
      selectedUser,
      selectedCategory
    }
  },

  reset() {
    $('#from-calendar').val("");
    $('#to-calendar').val("");
    this.setState({
      fromDate: '',
      toDate: '',
      selectedUser: "0",
      selectedCategory: categories[0]
    })
  },

  componentDidMount() {
    this.applyCalendarBehavior($(this.getDOMNode()).find('#from-calendar'));
    this.applyCalendarBehavior($(this.getDOMNode()).find('#to-calendar'));
    $('[placeholder]').placeholder();
  },

  applyCalendarBehavior(divElement, callback) {
    const localeFormat = Pmp.Utils.dateFormat(locale);
    let dateFormat;
    if (localeFormat === 'dd/mm/yy') {
      dateFormat = 'd M, yy';
    } else if (localeFormat === 'mm/dd/yy') {
      dateFormat = 'M d, yy';
    }
    divElement.attr('autocomplete', 'off');
    $(divElement).datepicker({
        dateFormat,
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow() {
            $('#ui-datepicker-div').addClass('customize');
        },
        onSelect(dateText, selObj) {
          let {selectedDay, selectedMonth, selectedYear, settings} = selObj;
          selectedMonth += 1;
          if (selObj.id === 'from-calendar') {
            settings.parentObj.setState({
              fromDate: selectedMonth + '/' + selectedDay + '/' + selectedYear
            });
          } else if (selObj.id === 'to-calendar') {
            settings.parentObj.setState({
              toDate: selectedMonth + '/' + selectedDay + '/' + selectedYear
            });
          }
          if (callback) {
              callback(dateText);
          }
        },
        prependZero(num) {
            return ("0" + num).slice(-2);
        }
    });
    $(divElement).datepicker("setDate", null, true);
  },

  onUpdateClick() {
    let fromDate = this.state.fromDate;
    if ($('#from-calendar').val() === "") {
      fromDate = "";
    }
    let toDate = this.state.toDate;
    if ($('#to-calendar').val() === "") {
      toDate = "";
    }
    this.props.data.updateHandler({
      fromDate,
      toDate,
      selectedUser: this.state.selectedUser,
      selectedCategory: this.state.selectedCategory
    });
  },

  onCategoryChange(e) {
    this.setState({ selectedCategory: $(e.target).val() });
  },

  onUserChange(e) {
    this.setState({ selectedUser: $(e.target).val() });
  },

  render() {
    return (
      <form id="search-and-filter-form" className="styled" method="GET">
        <div id="content-header">
          <div className="inline">
    				<div className="form-element cal text">
    						<label htmlFor="res-date-selector">
    								<p className="input">
    										<input id="from-calendar" className="cal-input date-placeholder" type="text" name="start_date_localized" placeholder="start date" />
    								</p>
    						</label>
    				</div>
    				<div className="form-element to">to</div>
    				<div className="form-element cal text">
    						<label htmlFor="res-date-selector">
    								<p className="input">
    										<input id="to-calendar" className="cal-input date-placeholder" type="text" name="end_date_localized" placeholder="end date"/>
    								</p>
    						</label>
    				</div>
            <div className="form-element select-filter user">
              <modules.formElements.create
                type='select'
                value={this.state.selectedUser}
                style={{fontFamily: 'Roboto'}}
                matchValue={false}
                name='user_select_filter'
                media_url={media_url}
                onChangeHandler={this.onUserChange}
                options={userNames}
                />
    				</div>
            <div className="form-element select-filter category">
              <modules.formElements.create
                type='select'
                value={this.state.selectedCategory}
                style={{fontFamily: 'Roboto'}}
                matchValue={true}
                name='category_select_filter'
                media_url={media_url}
                onChangeHandler={this.onCategoryChange}
                options={categories}
                />
    				</div>
    				<p className="button light action narrow">
    						<a id="search-reservations" className="filter-update-button" onClick={this.onUpdateClick}>
    								Update
    						</a>
    				</p>
    			</div>
        </div>
      </form>
    );
  }
})

export default FilterBar;
